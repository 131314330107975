<template>
  <div v-if="survey && answerInput" class="container text-left mw-42">
    <h3 class="mt-4 mb-3 text-center">{{ survey.surveyName }}</h3>
    <p>{{ survey.explainText }}</p>
    <p class="mb-4 font-weight-bold"><span class="text-danger font-weight-bold ">※</span>は必須入力です。</p>
    <div class="mb-12">
      <form ref="form1" @submit.prevent>
        <div v-for="question in this.survey.surveyQuestions" :key="question.id" class="form-group question-box mb-5">
          <!-- 設問タイトル -->
          <label class=""><span v-html="question.title"></span><span v-if="question.requiredFlg == 1" class="text-danger font-weight-bold" role="alert">※</span></label>
          <div v-if="question.answerType == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE && question.activityType == CONST.SURVEY.ACTIVITY_TYPE.RANGE" class="row text-left pl-3">
            <select v-model="answerInput[question.id]" :name="`answer-${question.id}`" :data-question-id="question.id" :ref="setInputRef" class="form-control col-sm-6">
              <option v-for="answer in question.surveyAnswers" :key="answer.id" :id="`input-${answer.id}`" :value="answer.id">
                {{ answer.title }}
              </option>
            </select>
          </div>
          <div v-else class="row text-left pl-3">
            <div v-if="question.answerType == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE && question.activityType == CONST.SURVEY.ACTIVITY_TYPE.ONE_TO_SEVEN" class="mx-0 px-0 w-100 row">
              <div class="col-3 text-left small px-0">全く当てはまらない</div>
              <div class="col-3 px-1 text-left">←</div>
              <div class="col-3 px-1 text-right">→</div>
              <div class="col-3 text-right small px-0">非常に当てはまる</div>
            </div>
            <template v-for="answer in question.surveyAnswers" :key="answer.id">
              <!-- 複数選択 -->
              <template v-if="question.answerType == CONST.SURVEY.ANSWER_TYPE.MULTIPLE_CHOICE" class="custom-control custom-checkbox mr-4">
                <input v-model="answerInput[question.id]" type="checkbox" :id="`input-${answer.id}`" class="custom-control-input" :name="`answer-${question.id}`" :data-question-id="question.id" :ref="setInputRef" :value="answer.id" />
                <label class="custom-control-label" :for="`input-${answer.id}`">{{ answer.title }}</label>
              </template>
              <!-- 単一選択 -->
              <template v-else-if="question.answerType == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE">
                <div v-if="question.answerType == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE && question.activityType == CONST.SURVEY.ACTIVITY_TYPE.ONE_TO_SEVEN" class="custom-control custom-radio mr-0 pl-0 text-center" style="width: 14.28%;">
                  <div class="custom-control custom-radio mr-0 pl-0">
                    <input v-model="answerInput[question.id]" type="radio" :id="`input-${answer.id}`" class="custom-control-input" :name="`answer-${question.id}`" :data-question-id="question.id" :ref="setInputRef" :value="answer.id" />
                    <label class="custom-control-label" :for="`input-${answer.id}`">{{ answer.title }}</label>
                  </div>
                </div>
                <div v-else class="custom-control custom-radio mr-4">
                  <input v-model="answerInput[question.id]" type="radio" :id="`input-${answer.id}`" class="custom-control-input" :name="`answer-${question.id}`" :data-question-id="question.id" :ref="setInputRef" :value="answer.id" />
                  <label class="custom-control-label" :for="`input-${answer.id}`">{{ answer.title }}</label>
                </div>
              </template>
              <!-- その他 -->
              <template v-else-if="question.answerType == CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS">
                <!-- レンジ入力 -->
                <div v-if="question.activityType == CONST.SURVEY.ACTIVITY_TYPE.RANGE" class="form-group col">
                  <label :for="`input-${answer.id}`">{{ answer.title }}</label>
                  <div class="row pr-3">
                    <div class="col">
                      <div class="row small">
                        <div class="col-3 text-left p-0">最大の<span class="text-nowrap">不幸</span></div>
                        <div class="col text-center pl-1 pr-1">←</div>
                        <div class="col-3 text-center p-0">どちらともいえない</div>
                        <div class="col text-center pl-1 pr-1">→</div>
                        <div class="col-3 text-right p-0">最高の<span class="text-nowrap">幸福</span></div>
                      </div>
                      <div class="row">
                        <input v-model="answerInput[question.id]" type="range" :id="`input-${answer.id}`" class="custom-range" :name="`answer-${question.id}`" :data-question-id="question.id" :ref="setInputRef" min="-100" max="100"/>
                      </div>
                      <div class="row small">
                        <div class="col text-left p-0">-100</div>
                        <div class="col text-center p-0">0</div>
                        <div class="col text-right p-0">100</div>
                      </div>
                    </div>
                  </div>
                  <div class="w-100 d-flex justify-content-center font-weight-bold mt-2 h5">
                    {{ answerInput[question.id] }} %
                  </div>
                </div>
                <!-- テキスト入力 -->
                <div v-else class="form-group">
                  <label class="col-form-label">{{ answer.title }}</label>
                  <input v-model="answerInput[question.id]" type="text" :id="`input-${answer.id}`" class="form-control" :name="`answer-${question.id}`" :data-question-id="question.id" :ref="setInputRef" />
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="my-5 text-center">
          <div class="mt-3 text-left">アンケートの回答が終わりましたら、応募フォームの入力をお願いします。</div>
        </div>
        <ul class="nav mt-3">
          <li><button type="button" class="btn_next btn btn-primary" @click="onClickNextButton">応募フォームへ</button></li>
        </ul>
        <ul class="nav mt-3">
          <li><button type="button" class="btn_next btn btn-secondary" @click="back">戻る</button></li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
import { Mixin } from '@/mixin.js'
import { sleep, checkStatus } from '@/utils/common.js'
import { apiError } from '@/api/common.js'
import { config, CONST } from '@/constants.js'
import { getAdvanceSurvey } from '@/api/survey.js'

export default {
  mixins: [Mixin],
  watch: {
    answerInput: {
      deep: true,
      async handler(e) {
        const nextSurveyQuestionIds = this.survey.surveyQuestions.reduce((arr, question) => {
          return question.surveyAnswers.reduce((arr2, answer) => {
            if (Object.keys(this.nextSurveyQuestionIds).indexOf(answer.id.toString()) !== -1) {
              if (!arr2[question.id]) arr2[question.id] = {}
              arr2[question.id][answer.id] = this.nextSurveyQuestionIds[answer.id]
            }
            return arr2
          }, arr)
        }, {})

        await this.slideProcess()
        for (const i in e) {
          if (Object.keys(nextSurveyQuestionIds).indexOf(i) !== -1) {
            if (e[i] && Object.keys(nextSurveyQuestionIds[i]).indexOf(e[i].toString()) !== -1) {
              nextSurveyQuestionIds[i][e[i]].forEach(qid => {
                // 暫定仮対応
                if (qid == 1007 && e[i] == 2058 && e[1003] != 2005) return true
                document.querySelector('[data-question-id="' + qid + '"]').closest('.question-box').classList.remove('d-none')
              })
            }
          }
        }
      }
    }
  },
  data() {
    return {
      CONST: CONST,
      // アンケートデータ
      survey: null,
      // ユーザーが入力した回答
      answerInput: {},
      // 設問のメタデータ
      questionMeta: {},
      // input要素
      inputRefs: {},
      dataSetFlg: false,
      mountedFlg: false,
      video2: config.VIDEO_ID2,
      viewFlg: false,
    }
  },
  async created() {
    // ステータスチェック
    checkStatus(this, 4)

    this.survey = await this.fetchSurvey()

    this.nextSurveyQuestionIds = this.survey.surveyQuestions.reduce((arr, question) => {
      return question.surveyAnswers.reduce((arr2, answer) => {
        if (answer.nextSurveyQuestionId) {
          arr2[answer.id] = answer.nextSurveyQuestionId.split(',').map(id => parseInt(id))
        }
        return arr2
      }, arr)
    }, {})

    const answerInputJson = localStorage.getItem('answerInputJson')
    if (answerInputJson) {
      try {
        const answerInput = JSON.parse(answerInputJson)
        this.answerInput = answerInput
      } catch (e) {
        console.log(e)
      }
    }

    // ユーザーの回答を初期化
    for (const question of this.survey.surveyQuestions) {
      this.answerInput[question.id] = (() => {
        if (
          question.answerType == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE &&
          question.activityType == CONST.SURVEY.ACTIVITY_TYPE.RANGE &&
          question.surveyAnswers[0]
        ) {
          return this.answerInput[question.id] || question.surveyAnswers[0].id
        }
        if (
          question.answerType == CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS &&
          question.activityType == CONST.SURVEY.ACTIVITY_TYPE.RANGE
        ) {
          return this.answerInput[question.id] || '0'
        }
        switch (question.answerType) {
          case CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS:
            return this.answerInput[question.id] || ''
          case CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE:
            return this.answerInput[question.id] || null
          case CONST.SURVEY.ANSWER_TYPE.MULTIPLE_CHOICE:
            return this.answerInput[question.id] || []
          default:
            return this.answerInput[question.id] || null
        }
      })()
      this.questionMeta[question.id] = {
        answerType: question.answerType,
        required: question.requiredFlg == 1,
      }
    }

    this.dataSetFlg = true
    if (this.mountedFlg) this.slideProcess()
  },
  mounted() {
    this.mountedFlg = true
    if (this.dataSetFlg) this.slideProcess()
  },
  methods: {
    async slideProcess() {
      await sleep(100)
      for (const id in this.nextSurveyQuestionIds) {
        this.nextSurveyQuestionIds[id].forEach(questionId => {
          document.querySelector('[data-question-id="' + questionId + '"]').closest('.question-box').classList.add('ml-4', 'd-none')
        })
      }
    },
    /**
     * アンケート取得
     */
    async fetchSurvey() {
      const res = await getAdvanceSurvey().catch(apiError)
      if (!res || !res.value) {
        return null
      }
      return res.value
    },
    /**
     * 生成されるinput要素をとっておく
     */
    setInputRef(el) {
      if (el) {
        const questionId = el.dataset.questionId
        if (this.inputRefs[questionId] == null) {
          this.inputRefs[questionId] = []
        }
        if (!this.inputRefs[questionId].includes(el)) {
          this.inputRefs[questionId].push(el)
        }
      }
    },
    /**
     * ボタン押下時の処理
     */
    async onClickNextButton() {
      this.validateAnswerInput()
      if (!this.$refs.form1.reportValidity()) {
        alert('必須項目を全てチェック、または記述していただくことで応募フォームへ進めます。')
        return
      }

      // localStorageに回答を保存
      localStorage.setItem('answerInputJson', JSON.stringify(this.answerInput))

      // 会員登録画面へ
      this.$router.push({name: 'Register'})
    },
    /**
     * ユーザーの回答の検証
     */
    validateAnswerInput() {
      const checkSurveyQuestions = []

      for (const questionId in this.answerInput) {
        const inputRef = this.inputRefs[questionId][0]
        inputRef.setCustomValidity('')

        let required = this.questionMeta[questionId].required
        const answerType = this.questionMeta[questionId].answerType
        if (this.nextSurveyQuestionIds[this.answerInput[questionId]]) {
          for (const nid of this.nextSurveyQuestionIds[this.answerInput[questionId]]) {
            checkSurveyQuestions.push(parseInt(nid))
          }
        }
        if (checkSurveyQuestions.indexOf(parseInt(questionId)) >= 0) required = true
        if (required) {
          const answer = this.answerInput[questionId]
          if (!this.inputRefs[questionId]) {
            continue
          }
          if (answerType === CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS && answer == '') {
            inputRef.setCustomValidity('この項目を入力してください。')
            return
          } else if (answerType === CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE && answer == null) {
            inputRef.setCustomValidity('いずれかを選択してください。')
            return
          } else if (answerType === CONST.SURVEY.ANSWER_TYPE.MULTIPLE_CHOICE && answer.length == 0) {
            inputRef.setCustomValidity('1つ以上選択してください。')
            return
          }
        }
      }
    },
    onYoutubeReady() {
      this.viewFlg = true
    },
  },
}
</script>

<style scoped>
.singleSelectTable :deep(td) {
  height: 5rem;
}
.singleSelectTable :deep(td label) {
  display: block;
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}
.singleSelectTable :deep(td label input[type='radio']) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
</style>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
